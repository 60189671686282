
import { Component, Vue } from "vue-property-decorator";
import {
  NavBar,
  Field,
  Loading,
  Icon,
  Divider,
  Cell,
  CellGroup,
  Popup,
  Button,
} from "vant";
import HotelOrderInfo from "@/components/hotelOrderInfo/index.vue";

import FootHotelPrice from "@/components/footHotelPrice/index.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Loading.name]: Loading,
    [Icon.name]: Icon,
    [Divider.name]: Divider,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [Button.name]: Button,
    HotelOrderInfo,
    FootHotelPrice,
  },
})
export default class HotelFill extends Vue {
  public hotelInfoData: any = {};

  public roomNum = 1;
  public checkPerson = [
    {
      nameList: [{ name: "" }],
    },
  ];
  public test = "";
  public changeRoomNum(type) {
    if (type == "jian") {
      if (this.roomNum <= 1) return this.$toast.fail("请至少选择1间房");
      this.roomNum--;
      this.checkPerson.pop();
    } else {
      if (this.roomNum >= 10) return this.$toast.fail("超过10间房请联系客服");
      this.roomNum++;
      this.checkPerson.push({
        nameList: [{ name: "" }],
      });
    }
    // for (let i = 0; i++; i <= this.roomNum) {}
    // console.log(this.checkPerson);
  }
  public addPeople(nameList) {
    nameList.push({ name: "" });
  }
  public delPeople(nameList, index) {
    nameList.splice(index, 1);
  }

  public ContactName = "";
  public ContactNumber = "";

  get getTotalPrice() {
    return this.hotelInfoData.TotalPrice * this.roomNum;
  }

  getFillData() {
    let current = true;
    let flag = this.checkPerson.every((item) =>
      item.nameList.every((ele) => ele.name)
    );
    if (!flag) {
      this.$toast("请填写入住人");
      current = false;
    }
    if (!this.ContactName) {
      this.$toast("请填写联系人");
      current = false;
    }
    if (!this.ContactNumber || !/^1[3-9][0-9]{9}$/.test(this.ContactNumber)) {
      this.$toast("请填写正确的联系电话");
      current = false;
    }
    let Names = this.checkPerson.map((item) =>
      item.nameList.map((ele) => ele.name).join("和")
    );

    if (!current) return;

    let queryObj = {
      Action: "SubmitOrder",
      toShopDate:
        this.$moment(new Date().getTime() + 1000 * 60 * 60 * 1).format("HH") +
        ":00",
      CheckInDate: this.hotelInfoData.CheckInDate,
      CheckOutDate: this.hotelInfoData.CheckOutDate,
      ContactName: this.ContactName,
      ContactNumber: this.ContactNumber,
      HotelCode: this.hotelInfoData.HotelCode,
      PlanCode: this.hotelInfoData.PlanCode,
      RoomCode: this.hotelInfoData.RoomCode,
      RoomCount: this.roomNum,
      SupplierCode: this.hotelInfoData.SupplierCode,
      TotalPrice: this.getTotalPrice,
      Names,
    };

    return queryObj;
  }
  public nextStep() {
    if (!this.getFillData()) return;

    let hotelInfo = {
      roomNum: this.roomNum,
      ContactName: this.ContactName,
      ContactNumber: this.ContactNumber,
      ...this.hotelInfoData,
      queryObj: this.getFillData(),
      checkPerson: this.checkPerson,
    };
    this.$router.push({
      path: "/hotelConfirm",
      query: {
        hotelInfoData: JSON.stringify(hotelInfo),
      },
    });
  }

  getHotelInfoData() {
    this.hotelInfoData =
      (this.$route.query &&
        this.$route.query.hotelInfo &&
        JSON.parse(this.$route.query.hotelInfo + "")) ||
      {};
    this.hotelInfoData.cityName =
      (sessionStorage.getItem("queryHotelData") &&
        JSON.parse(sessionStorage.getItem("queryHotelData")).CityName) ||
      "";
    console.log("hotelInfoData", this.hotelInfoData);
  }
  created() {
    this.getHotelInfoData();
  }
  activated() {
    this.getHotelInfoData();
  }
}
